export class PermissionGroupModel {
    name: string;
    permissions: PermissionModel[];
    isChecked?: boolean;
}

export class PermissionModel {
    name: string;
    value: number;
    isChecked?: boolean;
}
export class RoleModel {
    id?: string;
    roleName: string;
    createdDate?: string;
    createdBy?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    isActive: boolean;
    permissions: number[];
}

export enum PermissionAction {

    //#region User
    CreateUser = 1001,
    UpdateUser = 1002,
    DeleteUser = 1003,
    ViewUser = 1004,
    ViewAllUser = 1005,
    UpdateAllUser = 1006,
    //#endregion

    //#region Product
    CreateProduct = 2001,
    UpdateProduct = 2002,
    DeleteProduct = 2003,
    ViewProduct = 2004,
    ViewAllProduct = 2005,
    //#endregion

    //#region Category
    CreateCategory = 3001,
    UpdateCategory = 3002,
    DeleteCategory = 3003,
    ViewCategory = 3004,
    ViewAllCategory = 3005,
    //#endregion

    //#region Role
    CreateRole = 4001,
    UpdateRole = 4002,
    DeleteRole = 4003,
    ViewRole = 4004,
    ViewAllRole = 4005,
    ViewPermission = 4010,
    //#endregion

    //#region Order
    CreateOrder = 5001,
    UpdateOrder = 5002,
    DeleteOrder = 5003,
    ViewOrder = 5004,
    ViewAllOrder = 5005,
    //#endregion

    //#region Cart
    CreateCart = 6001,
    UpdateCart = 6002,
    DeleteCart = 6003,
    ViewCart = 6004,
    ViewAllCart = 6005,
    //#endregion

    //#region Article
    CreatePostCategory = 7001,
    UpdatePostCategory = 7002,
    DeletePostCategory = 7003,
    ViewPostCategory = 7004,

    CreatePost = 7011,
    UpdatePost = 7012,
    DeletePost = 7013,
    ViewPost = 7014,

    CreateBlogConfig = 7021,
    UpdateBlogConfig = 7022,
    DeleteBlogConfig = 7023,
    ViewBlogConfig = 7024,
    //#endregion

    //#region Tag

    CreateTag = 9001,
    UpdateTag = 9002,
    DeleteTag = 9003,
    ViewTag = 9004,

    //#endregion

    //#region Configuration
    CreateConfiguration = 8001,
    UpdateConfiguration = 8002,
    DeleteConfiguration = 8003,
    ViewConfiguration = 8004,
    ViewAllConfiguration = 8005,
    //#endregion

    //#region Job
    DeleteJob = 12003,
    //#endregion
}

export function IsAuthurization(userPermissions: number[], requiredPermission: number | number[] | undefined): boolean {
    
    if (!Array.isArray(requiredPermission) && typeof requiredPermission !== 'undefined' && userPermissions.includes(requiredPermission) || !requiredPermission) {
        return true;
    } else if (Array.isArray(requiredPermission) && requiredPermission?.length &&
        requiredPermission.every(action => userPermissions?.includes(action))) {
        return true;
    } else {
        return false
    }
}